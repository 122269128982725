import React from "react";

import globalStyles from "./styles";
import clsx from "clsx";


export default function InfoBar({ children, wide }) {
    const classes = globalStyles();

    if (wide) {
        return (
            <div className={ clsx(classes.wide, {
                [classes.bgWhite]: ( wide === "white"),
                [classes.bgDarkBlue]: (wide === "darkBlue")
            }) }>
                <div className={classes.infobarContainer}>
                    { children }
                </div>
            </div>
        );
    } else {
        return (
            <div className={ classes.infobarContainer }>
                { children }
            </div>
        );
    }
}
