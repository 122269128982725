import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import globalStyles from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    title: {
        [theme.breakpoints.between("xxs", "xs")]: {
            fontSize: "22px",
            letterSpacing: "0em",
        },
        [theme.breakpoints.between("s", "sm")]: {
            fontSize: "25px",
            letterSpacing: "0.1em",
        },
        [theme.breakpoints.up("sm")]: {
            letterSpacing: "0.4em",
        },
    },
    untertitle: {
        [theme.breakpoints.between("xxs", "xs")]: {
            fontSize: "10px",
            letterSpacing: "0.0em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            letterSpacing: "0.1em",
        },
    }
}));

export default function Logo() {
    const classes = globalStyles();
    const styles = useStyles();

    const data = useStaticQuery(graphql`
    query {allFile(filter: {name: {eq: "logo_hell"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
  }
    `);

    const content =
         data.allFile.edges.map((o, ind) => {
             return (
                 <div className={classes.rootLogo}
                     key={ind}>
                     <Link href="/">
                         <div className={classes.logoLink}> </div>
                     </Link>
                     <div key={ind} className={classes.firstChildLogo}>

                         <div className={classes.div1Logo}>
                             <img  src={o.node.publicURL}
                                 alt="logo"
                                 className={classes.logo}/>
                         </div>
                         <div className={classes.div2Logo}>
                             <h1 className={ clsx( classes.logoTitle, styles.title ) }>
                                FEHLZEITENERFASSUNG
                             </h1>
                         </div>
                         <hr/>
                         <div className={classes.div3Logo}>
                             <p className={ clsx( classes.logoUntertitle, styles.untertitle ) }>
                                SYSTEMATISCH · EFFIZIENT · FLEXIBEL
                             </p>
                         </div>
                     </div>
                 </div>
             );});


    return <> {content} </>;

}
