//import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

import globalStyles from "./styles";


const Footer = () => {
    const classes = globalStyles();

    const data = useStaticQuery(graphql`
    query {allFile(filter: {name: {eq: "School_doodles_2"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
  }
    `);
    const content =
         data.allFile.edges.map((o, ind) => {
             return (
                 <div key={ind}>
                     <img  src={o.node.publicURL}
                         alt="logo"/>
                 </div>
             );});



    return (
        <footer className={classes.footer}>
            <div className="footerWrapper">
                <div
                    className={classes.divParentFooter}
                    id="divParentFooter"
                >
                    <div
                        className={classes.divFooterItem}
                        id="divFooterItem"
                    >
                        Diese Seite wird betrieben von:
                        <br/>
                        <a 
                            className={classes.footerLink}
                            href="https://eduflux.de">
                            eduFlux GmbH
                        </a>
                    </div>
                    <div 
                        className={classes.divFooterItem}
                        id="divFooterItem"
                    >
                        <Link to="/impressum/"
                            className={classes.footerLink}>
                            Impressum
                        </Link>
                        <br />
                        <Link to="/datenschutz/"
                            className={classes.footerLink}>
                            Datenschutzhinweise
                        </Link>
                    </div>
                    <div 
                        className={classes.divFooterItem}
                        id="divFooterItem"
                    >
                        <a 
                            className={classes.footerLink}
                            href="https://doc.fehlzeitenerfassung.de">
                            Dokumentation
                        </a>
                        <br/>

                        <a 
                            className={classes.footerLink}
                            href="mailto:hilfe@fehlzeitenerfassung.de">
                            hilfe@fehlzeitenerfassung.de
                        </a>
                    </div>
                </div>
                <div className={ classes.divFooterLogo }>
                    {content}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
