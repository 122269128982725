import React from "react";
import PropTypes from "prop-types";

import globalStyles from "./styles";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import HrPen from "./hr/HrPen";
import HrZwecke from "./hr/HrZwecke";
import HrBuch from "./hr/HrBuch";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "0px",
        },
        [theme.breakpoints.between("xs", "s")]: {
            marginTop: "-30px",
        },
        [theme.breakpoints.between("s", "sm")]: {
            marginTop: "-30px",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "-10px",
        },
    },
}));

export default function InfoTitle({ type }) {
    const classes = globalStyles();
    const styles = useStyles();

    let content = null;
    switch(type) {
        case "1":
        case "4":
            content = <HrBuch />;
            break;
        case "2":
            content = <HrPen />;
            break;
        default:
            content = <HrZwecke />;
    }

    return (
        <div className={ clsx(classes.infoTitleContainer, styles.root, {
            [classes.bgtype1]: (type == "1"),
            [classes.bgtype2]: (type == "2"),
            [classes.bgtype3]: (type == "3"),
            [classes.bgtype4]: (type == "4"),
        }) }>
            {content}
        </div>
    );

}


InfoTitle.propTypes = {
    type: PropTypes.string.isRequired,
};
