/* @format */
import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import globalStyles from "../styles";

const HrBuch = () => {
    const classes = globalStyles();
    const {
        file: { publicURL }
    } = useStaticQuery(graphql`
        query {
            file(
                relativePath: { eq: "assets/unterteilungen/teilungbook.svg" }
            ) {
                publicURL
            }
        }
    `);
    return <img src={publicURL} alt="teilung" className={classes.teilungen} />;
};

export default HrBuch;
