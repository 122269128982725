import { makeStyles } from "@material-ui/core/styles";
// const hellgruen = "#d1efdf";
// const gruen = "#8fd8b5";
// const grau = "#e1dfdb";

const white = "#fafafa";
const dunkelBlau = "#202D46";

const schwarz = "#3f3f41";




export default makeStyles((theme) => ({
    infobarContainer: {
        maxWidth: 1024,
        margin: `${theme.spacing(2)}px auto`,
        marginBottom: "0px",
        paddingBottom: 20,
        paddingTop: 20
    },
    flexboxParent: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "stretch",
        justifyContent: "space-around",
        margin: "0 10px 0 10px",
    },
    rootLogo: {
        display: "flex",
        justifyContent: "center",
        color: "#676a6b",
        "&> a:focus": {
            borderBottom: "3px solid",
            marginBottom: "-3px",
            outline: "none",
        },
    },
    logoLink: {
        height: "190px",
        width: "500px",
        backgroundColor: "rgba(0, 0, 0, 0)"
    },
    firstChildLogo: {
        textAlign: "center",
        position: "fixed",
        top:"40px",
        zIndex: -1,
    },
    div1Logo: {
        display: "inline-block"
    },
    logo: {
        marginBottom: "-20px",
        maxHeight: "5rem"
    },
    logoTitle: {
        mozTextShadow: "5px -20px 3px #f2f1f1",
        webkitTextShadow: "5px -20px 3px #f2f1f1",
        textShadow: "5px -20px 3px #f2f1f1",

    },
    logoUntertitle: {
        letterSpacing: "0.5em",
        mozTextShadow: "5px -20px 3px #f2f1f1",
        webkitTextShadow: "5px -20px 3px #f2f1f1",
        textShadow: "5px -8px 2px #e8e8e8",
    },
    div2Logo: {
        display: "inline-block",
        marginLeft: "15px"
    },
    div3Logo: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-15px"
    },
    buttonInBar: {
        "&:hover": {
            background: "#009800",
        },
        backgroundColor: "#ffc541",
        border: "1px solid #FFFFFF",
        color: "#FFFFFF",
        fontWeight: "bolder",
        marginBottom: "3em"
    },
    infoTitleContainer: {
        marginBottom: "50px"
    },
    bgtype1: {
        marginBottom: "-30px",
        paddingTop: 30,
        background: "-webkit-linear-gradient(-85.5deg, rgba(98, 98, 98, 0.001) 46%, #8bb830 47%, #202D46 48%)"
    },
    bgtype2: {
        marginTop: "-20px",
        marginBottom: "-53px",
        background: "-webkit-linear-gradient(87deg, #fafafa 35%, #8bb830 36%, #202D46 37%)"
    },
    bgtype3: {
        marginTop: "-20px",
        marginBottom: "-49px",
        background: "-webkit-linear-gradient(-86.5deg, #fafafa 53%, #8bb830 54%, #202D46 55%)"
    },
    bgtype4: {
        paddingTop: 10,
        marginBottom: -20,
        background: "-webkit-linear-gradient(-86.5deg, rgba(98, 98, 98, 0.001) 45%, #8bb830 46%, #fafafa 47%)"
    },
    teilungen: {
        height: "100%",
        width: "100%",
        zIndex: 5
    },
    flexContainer:{
        padding: "1em",
        textAlign: "left"
    },
    wide: {
        width: "100%",
    },
    bgWhite: {
        backgroundColor: white
    },
    bgDarkBlue: {
        backgroundColor: dunkelBlau,
        color: "#fafafa",
    },

    flexBoxTrue: {
        maxWidth: 450
    },
    flexBoxFalse: {
        display: "flex",
        flexWrap: "wrap",
        paddingTop: "30px",
        paddingBottom: "30px",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 10px 0 10px",
        flexDirection: "column"
    },
    flexBoxIconDiv: {
        backgroundColor: "#FFFFFF",
        width: "120px",
        height: "120px",
        border: "2px solid #C52833",
        borderRadius: "50%",
        padding: 20
    },
    flexBoxIcon: {
        maxWidth: 90,
        maxHeight: 90,
        position: "relative",
        top: -38,
        left: 15,
    },
    flexBoxIconMUI: {
        fill: schwarz,
        fontSize: 90,
        marginLeft: "10%",
        marginTop: "-30%"
    },
    infoBoxFalseInfo: {
        marginLeft: "20px",
        maxWidth: 850,
    },
    infoBoxTitle: {
        borderBottom: "2px solid #C52833",
        paddingBottom: "5px",
        marginRight: "10px"
    },

    imagesGallery: {
        height: "200px",
        width: "200px",
    },
    imagesListRoot: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        maxWidth: 900
    },
    imagesGridList3_4: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
        axWidth: 800,
    },
    imagesGridList1: {
        width: 500,
        height: 450
    },
    galleryArrowButton: {
        "&:hover": {
            background: "rgba(23, 23, 24, 0.2)",
        },
        paddingTop: "8%",
        paddingLeft: "1%",
        paddingRight: "1%"
    },
    greybox: {
        backgroundColor: "#e1dfdb",
        padding: theme.spacing(2)
    },

    ULItem: {
        marginBottom: "0px"
    },

    buttonLink: {
        display: "inline-block",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        border: "1px solid ", schwarz,
        marginLeft: "5px",
        position: "absolute",
        marginTop: "5px",

    },
    link: {
        textDecoration: "none",
        fontWeight: "bolder",
        fontSize: 16
    },
    linkIcon: {
        position:"relative",
        top: -5,
        left: -3
    },

    footer: {
        maxWidth: "100%",
        background: schwarz,
        color: "#fafafa",
        fontsize: "18px",
        margin: 0,
        padding: "0.5rem",
    },
    divParentFooter: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-around",
        width: "85vw",
    },
    divFooterItem: {
        maxWidth: 500,
        fontSize: 18
    },
    footerLink: {
        textDecoration: "none",
        fontWeight: "bolder",
        fontSize: 18
    },
    divFooterLogo: {
        order: "2",
        marginRight: "3vw",
        maxWidth: "6rem",
    },

    infoMD: {
        margin: `${theme.spacing(2)}px auto`,
        marginTop: "200px"
    },

    loader: {
        position: "fixed",
        background: "rgba(20.7, 29.4, 38.2, 0)",
        top: 0,
        right: 0,
        width: "1em",
        height: "1em",
        zIndex: 100,
        overflow: "hidden",
        transition: "all 400ms",

    },

    loader1: {
        height: "100%",
        width: "100%",
        background: "rgba(20.7, 29.4, 38.2, 0.5)",
    },

    backButton:{
        backgroundColor: white,
        textAlign: "right",
        paddingBottom: "10px",
        paddingRight: "10px"
    },

    headerUnterseiten: {
        textAlign: "center"
    },

    textCenter: {
        textAlign: "center",
        margin: "0 10px 0 10px",
    },

    fehlzeitenerfassung: {
        color: "#8bb830",
    },

    logoBoxImMenu: {
        backgroundColor: "#fafafa",
        textAlign: "center",
        alignItems: "center",
        width: "inherit"
    },
    logoImMenu: {
        width: "6rem",
        marginTop: "1rem",
        marginBottom: "0.5rem",
    },

    linkImMenu: {
        paddingBottom: "2rem !important",
    },

    linkList: {
        "&:hover, &:focus": {
            mozTextShadow: "0 0 10px #fafafa",
            webkitTextShadow: "0 0 10px #fafafa",
            textShadow: "0 0 10px #fafafa",
            color: "#fafafa"
        }
    },
    linkStart: {
        paddingLeft: "0px !important",
    },
    colorGreen: {
        color: "#8bb830" ,
    },
    colorGreenIcon: {
        color: "#8bb830" ,
        position: "absolute !important",
        right: "10px !important",
        marginTop: "-30px !important"
    },

    listRoot: {
        backgroundColor: "#202D46",
        color: "#fafafa",
        height: "100%"
    },
    listItem: {
        "&:hover": {
            backgroundColor: "#202D46",
        },
        backgroundColor: "#202D46",
        display: "block !important",
        padding: "0.3rem 1rem 0 1rem !important",
        margin: "0px !important",
        position: "initial !important",
    },

    blogPostContainer: {
        margin: "0px 10px 0px 10px",
    },


}));
